<template>
    <div>
        <b-sidebar aria-labelledby="sidebar-no-header-title" no-header id="pview_invoices" backdrop style="direction:ltr" width="90%" right :title="lang.add_pay_type" shadow >
            <template #default="{ hide }">
                <div class="m-1 text-start" style="direction:rtl">
                    <div style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                    <span>اوامر العمل</span>
                    </div>
                    <div @click="hide" id="closeaddpayment" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm closethis">
                    <span>{{lang.close}}</span>
                </div>
                </div>
                <v-divider></v-divider>
                <div class="m-2" style="direction:rtl">
                    
                    <v-row :style="`margin:auto;width:100%;direction:rtl;text-align:right`">
                        <b-table-simple :style="`direction:`+lang.dir">
                            <thead>
                                <tr>
                                    <th class="backBlack text-center">رقم البطاقة</th>
                                    <th class="backBlack text-center">وصف العمل</th>
                                    <th class="backBlack text-center" v-if="$store.state.licenseType.cars">{{lang.plate_number}}</th>
                                    <th class="backBlack text-center">{{lang.received_date}}</th>
                                    <th class="backBlack text-center">{{ lang.total }}</th>
                                    <th class="backBlack text-center">{{ lang.vat }}</th>
                                    <th class="backBlack text-center">{{ lang.ftotal }}</th>
                                    <th class="backBlack text-center">الحالة</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in tbRows" :key="index">
                                    <td class="text-center">{{ item.card_id }}</td>
                                    <td class="text-center">{{ item.item_name }}</td>
                                    <td class="text-center" v-if="$store.state.licenseType.cars">{{ item.plate_number }}</td>
                                    <td class="text-center">{{ item.received_date }}</td>
                                    <td class="text-center">{{ item.total }}</td>
                                    <td class="text-center">{{ item.vat }}</td>
                                    <td class="text-center">{{ item.ftotal }}</td>
                                    <td class="text-center">{{ item.status }}</td>
                                </tr>
                            </tbody>
                        </b-table-simple>
                    </v-row>
                </div>
            </template>
        </b-sidebar>
    </div>
</template>

<script>
import axios from 'axios'
export default{
    components: {
        
    },
    data() {
        return {
            tbRows: [],
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        ldir: function(){
            return this.lang.ldir;
        },
        lalgin: function(){
            return this.lang.lalgin;
        },
        items: function(){
            return {
                text: this.lang.quotations,
                disabled: true,
                href: '/view-quot',
            }
        },
        ecars: function(){
            // // console.log("this is cars",this. quot.cars);
            return this.quot.cars;
        },
        fulltotals: function(){
            let t = {
                total: 0,
                ftotal: 0,
                vat: 0
            }
            
            for(let i=0;i<this.quot.rows.length;i++){
                t.total = this.$RoundNums(parseFloat(this.quot.rows[i].total) + parseFloat(t.total));
                t.ftotal = this.$RoundNums(parseFloat(this.quot.rows[i].ftotal) + parseFloat(t.ftotal));
                t.vat = this.$RoundNums(parseFloat(this.quot.rows[i].vat) + parseFloat(t.vat));
            }
            return t;
        }
    },
    methods: {
        getIt(id){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type" , "getEmpWork");
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[id]',id)
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((resposne) => {
                this.tbRows = resposne.data.results.data
            })
        },
        updateMe(){
            if(this.quotid == 0) return false;
            // console.log(this.$parent.$refs.addQuot);
            this.$parent.$refs.addQuot.getQuotation(this.quotid)
        },
        changeMe(){
            // console.log(this.newrow)
            const itemn = this.newrow.item_name.split(" -||- ");
            if(itemn.length > 1){
                this.newrow.itemcode = itemn[0];
                this.getItem();
            }
        },
        getItem(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type" , "getProducts");
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[itemcode]',this.newrow.itemcode)
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((resposne) => {
                if(resposne.data.results.data.length != 0){
                    this.newrow = {
                        itemcode: resposne.data.results.data[0].item_code,
                        description: resposne.data.results.data[0].description,
                        item_name: resposne.data.results.data[0][`item_name${this.lang.langname}`],
                        qty: 1,
                        item_price: resposne.data.results.data[0].default_price,
                        total: 0,
                        vat: 0,
                        ftotal: 0,
                        plate_number: '',
                        discount: resposne.data.results.data[0].discount
                    }
                    this.calcKit()
                }
            });
        },
        calckIt(index){
            //const row = this.quot.rows[index];
            const pr = parseFloat(this.newrow.item_price) - parseFloat(this.newrow.discount)
            let t = parseFloat(this.newrow.qty) * parseFloat(pr);
            const trs = this.$calcVat(t,this.quot.vtype);
            this.newrow.total = this.$RoundNums(trs.tot,2);
            this.newrow.vat = this.$RoundNums(trs.vat,2);
            this.newrow.ftotal = this.$RoundNums(trs.ftot,2);
        },
        openWhatsAPP2(){
            const mobile = this.quot.mobile.substring(1);

            window.open("https://wa.me/966"+mobile,"_blank");
        },
        openWhatsAPP(){
            this.$refs.sendClientWhats.number_list = this.customer.mobile
            this.$refs.sendClientWhats.getOldMessages();
        },
        updateQuot(status){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type" , "updateQuot"); 
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append("data[id]",this.quotid);
            post.append("data[status]",status);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((resposne) => { 
                // // console.log(resposne.data)
                this.$parent.getQuotations()
            })            
        },
        doAddInv() {
            this.$refs.addInvo.invoice.vtype = this.quot.vtype
            this.$refs.addInvo.invoice.notes = this.quot.notes
            this.$refs.addInvo.invoice.card_type = this.quot.card_type
            this.$refs.addInvo.quotID = this.quot.id;
            this.$refs.addInvo.customer.mobile = this.quot.mobile;
            this.$refs.addInvo.getName();
            this.$refs.addInvo.changeCusType(parseInt(this.quot.customer_type) - 1);
            this.$refs.addInvo.tbrows = this.quot.rows;
            this.$refs.addInvo.changeCoData({
                address: this.quot.address,
                branch_name: this.quot.branch_name,
                company_name: this.quot.company_name,
                entity_name: this.quot.entity_name,
                vatid: this.quot.vatid,
                crt_number: this.quot.crt_number,
            });
            this.$refs.addInvo.cars = this.quot.cars;
            this.$refs.addInvo.ecars = this.quot.cars;
            this.$refs.addInvo.calcAll();
            this.$refs.addInvo.company.cctype = this.quot.customer_type;
        },
        printQuot(){
            window.open('../../api/print.php?quotid='+this.quotid,'_blank');
        },
        delet(id){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type', "deleteQuotationRows");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase))
            post.append('data[quotid]',this.quotid)
            post.append('data[id]',id)
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                // console.log(response.data)
                this.getQuotations()
            })
        },
        addNewRow(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type', "updateQuotationRows");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase))
            post.append('data[id]',this.quotid)
            post.append('data[newrow][plate_number]',this.newrow.plate_number)
            post.append('data[newrow][itemcode]',this.newrow.itemcode)
            post.append('data[newrow][item_name]',this.newrow.item_name)
            post.append('data[newrow][qty]',this.newrow.qty)
            post.append('data[newrow][item_price]',this.newrow.item_price);
            post.append('data[newrow][total]',this.newrow.total);
            post.append('data[newrow][vat]',this.newrow.vat);
            post.append('data[newrow][ftotal]',this.newrow.ftotal);
            post.append('data[newrow][discount]',this.newrow.discount);
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then(() => {
                this.getQuotations()
            })
        },
        getQuotations() {
            
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type', "getQuotations");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase))
            post.append('data[id]',this.quotid)
            post.append('data[status]',0)
            // // console.log(this.quot);

            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                const res = response.data;
                // // console.log("this is the quotation ",res);
                this.quot = res.results.data[0];
                this.carsinfo = []
                console.log("this.quot",this.quot)
                for(let i=0;i<this.quot.cars.length;i++){
                    this.carsinfo.push({text: this.quot.cars[i].plate_number,value: this.quot.cars[i].plate_number})
                    this.newrow.plate_number = this.quot.cars[0].plate_number
                }
                this.newrow.itemcode = ''
                this.newrow.item_name = ''
                this.newrow.qty = 1
                this.newrow.item_price = 0
                this.newrow.discount = 0
                this.calckIt()
            })

        },
        getInvSettings() {
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','getInvSettings');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[all]','all');
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    if(res != '' && res.error.number == 200){
                        if(res.results.data.users.length != 0){
                            for(let i= 0; i < res.results.data.users.length; i++){
                                this.invoice.users.push({
                                    text: res.results.data.users[i].full_name,
                                    value: res.results.data.users[i].id
                                })
                            }
                        }
                        if(res.results.data.products.length != 0){
                            this.itemsworkdes = [];
                            // this.allProducts = res.results.data;
                            this.itemsworkdes = res.results.data.products;
                            
                            // for(let i= 0; i < res.results.data.products.length; i++){
                            //    this.itemsworkdes.push(res.results.products[i].name);
                            // }
                        }
                    }
                }
            )
        },
        printPDF ()
        {
            let pdfFrame = document.body.appendChild(document.createElement('iframe'));
            pdfFrame.style.display = 'none';
            pdfFrame.onload = () => (void pdfFrame.contentWindow.print());
            pdfFrame.src = '../../api/print.php?quotid='+this.quotid;
        },
    },
    created(){
        this.getInvSettings();
    }
}
</script>
<style>
.myRof{
    display:flex;
    flex-direction: row;
    justify-content: center;
    justify-items:center;
    align-items: center;
}
</style>